<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-12">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Operación</li>
                  <li class="breadcrumb-item active">Cargue Programaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <fieldset class="well card">
              <legend class="well-legend text-bold bg-frontera text-light">
                {{ accion }} Cargue Programaciones
                <span v-if="form.id"> - {{ form.id }}</span>
              </legend>

              <div class="row mt-0 mb-3">
                <div class="col-md-5">
                  <label for="nominaciones">Nominación</label>
                  <v-select
                    :class="[
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                      $v.form.nominacion_id.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                    ]"
                    placeholder="Nominaciones"
                    label="nombre"
                    v-model="nominacion"
                    :disabled="bloquear"
                    class="form-control form-control-sm shadow-sm p-0"
                    @input="validarNominación()"
                    :options="listasForms.nominaciones"
                  >
                  </v-select>
                  <!-- <select
                    :disabled="bloquear"
                    class="form-control form-control-sm"
                    v-model="nominacion"
                    @change="validarNominación()"
                  >
                    <option
                      v-for="nominaciones in listasForms.nominaciones"
                      :key="nominaciones.id"
                      :value="nominaciones.id"
                    >
                      {{ nominaciones.nombre }}
                    </option>
                  </select> -->
                </div>
                <div class="col-md-3"></div>
                <div v-if="form.id" class="col-md-4">
                  <label for="estado">Estado</label>
                  <select
                    :disabled="bloquear"
                    class="form-control form-control-sm"
                    v-model="form.estado"
                    @change="validarEstado()"
                  >
                    <option
                      v-for="estado in listasForms.estados"
                      :key="estado.numeracion"
                      :value="estado.numeracion"
                    >
                      {{ estado.descripcion }}
                    </option>
                  </select>
                </div>
              </div>
              <div
                class="row justify-content-center"
                v-if="!$v.form.nominacion_id.$invalid"
              >
                <div class="col-md-4 mb-3">
                  <div
                    class="small-box alert-default-info shadow m-2"
                    style="min-height: 100px"
                  >
                    <div class="inner">
                      <div class="col-md-9">
                        <h6><b>Nombre</b></h6>
                        <input
                          :disabled="bloquear"
                          type="text"
                          class="form-control form-control-sm"
                          v-model="form.nombre"
                          :class="
                            $v.form.nombre.$invalid ? 'is-invalid' : 'is-valid'
                          "
                        />
                      </div>
                    </div>
                    <div class="icon icon-sm">
                      <i class="ion ion-more text-navy"></i>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3">
                  <div
                    class="small-box alert-default-info shadow m-2"
                    style="min-height: 100px"
                  >
                    <div class="inner">
                      <div class="col-md-9">
                        <h6><b>Fecha Programación</b></h6>
                        <div class="row">
                          <input
                            type="date"
                            :disabled="bloquear"
                            v-model="form.fecha_vigencia"
                            style="font-size: 13px"
                            :min="nominacion.fecha_inicial"
                            :max="nominacion.fecha_final"
                            class="form-control form-control-sm col-md-11 mr-1"
                            :class="
                              $v.form.fecha_vigencia.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div class="icon icon-sm">
                      <i class="ion ion-calendar text-navy"></i>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 mb-3 justify-content-center">
                  <div
                    class="small-box alert-default-info shadow m-2"
                    style="min-height: 100px"
                  >
                    <div class="inner">
                      <div class="col-md-9">
                        <h6 class="text-center"><b>Observación</b></h6>
                        <textarea
                          :disabled="bloquear"
                          v-model="form.observaciones"
                          class="form-control form-control-sm"
                        ></textarea>
                      </div>
                    </div>
                    <div class="icon icon-sm">
                      <i class="ion ion-compose text-navy"></i>
                    </div>
                  </div>
                </div>

                <!-- ALERTA DE PROGRAMACION VENCIDA -->
                <div
                  v-if="vencida && form.estado == 1"
                  class="form-group col-md-11 btn alert-default-warning image-preview-input"
                >
                  <h5>
                    <i class="icon fas fa-exclamation-triangle"></i>
                    Atención!
                  </h5>
                  La fecha de la programación es inferior a la actual, No es
                  posible realizar importar plantilla.
                </div>

                <div
                  :class="plantilla_cargue == null ? 'col-md-2' : 'col-md-1'"
                ></div>
                <div
                  class="col-md-6 mb-3"
                  v-if="
                    accion == 'Editar' &&
                      form.estado == 1 &&
                      !vencida &&
                      ((cargue_programaciones.data &&
                        !cargue_programaciones.data.length > 0) ||
                        !cargue_programaciones.data)
                  "
                >
                  <div
                    class="small-box alert-default-info shadow m-2"
                    style="min-height: 100px"
                  >
                    <div class="inner">
                      <h6><b>Importar Plantilla</b></h6>
                      <div
                        class="col-md-12 mt-2"
                        :class="
                          plantilla_cargue == null
                            ? 'alert-default-warning border border-warning'
                            : 'alert-default-success border border-success'
                        "
                      >
                        <input
                          type="file"
                          id="file"
                          ref="file"
                          @change="elegirDirectorio()"
                          accept=".xlsx, .CSV"
                          class="form-control btn btn-secundary"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-4 mt-4"
                  v-if="
                    accion == 'Editar' &&
                      form.estado == 1 &&
                      !vencida &&
                      ((cargue_programaciones.data &&
                        !cargue_programaciones.data.length > 0) ||
                        !cargue_programaciones.data)
                  "
                >
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn bg-olive mr-1 shadow"
                      title="Plantilla"
                      @click="descargarPlantilla()"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.cargueProgramaciones.exportPlantilla'
                        )
                      "
                    >
                      <i class="fas fa-file-download"></i> Plantilla
                    </button>
                    <button
                      type="button"
                      class="btn bg-primary mr-1 shadow-sm"
                      title="Subir Plantilla"
                      @click="importarCargue()"
                      v-if="
                        plantilla_cargue &&
                          !$v.form.$invalid &&
                          $store.getters.can(
                            'hidrocarburos.cargueProgramaciones.import'
                          )
                      "
                    >
                      <i class="fas fa-file-upload"></i> Subir Plantilla
                    </button>
                    <button
                      type="button"
                      class="btn bg-danger shadow-sm"
                      @click="limpiarPlantilla()"
                      title="Limpiar Plantilla"
                      v-if="plantilla_cargue"
                    >
                      <i class="fas fa-trash-alt"></i> Limpiar
                    </button>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center">
                <button
                  type="button"
                  class="btn bg-gradient-primary col-md-1 shadow mr-1"
                  title="Plantilla"
                  @click="save()"
                  v-if="
                    $store.getters.can(
                      'hidrocarburos.cargueProgramaciones.exportPlantilla'
                    ) &&
                      !$v.form.$invalid &&
                      !bloquear
                  "
                >
                  <i class="fas fa-paper-plane"></i>
                  <div>Guardar</div>
                </button>
                <button
                  class="btn bg-gradient-secondary col-md-1 shadow"
                  @click="back()"
                >
                  <i class="fas fa-reply"></i>
                  <div>Volver</div>
                </button>
              </div>
            </fieldset>
            <fieldset class="well card" v-if="accion == 'Editar'">
              <legend class="well-legend text-bold bg-frontera text-light">
                Filtros - <span v-if="form.estado == 1">Acciones</span>
                <span v-if="form.estado == 2">Detalles</span>
              </legend>
              <div class="col-md-12 p-0">
                <div class="btn-group float-right border-0">
                  <div class="card-tools">
                    <button
                      type="button"
                      class="btn bg-navy mr-2"
                      title="Actualizar"
                      @click="getIndex()"
                    >
                      <i class="fas fa-sync-alt fa-spin"></i>
                    </button>
                    <button
                      type="button"
                      class="btn bg-olive mr-2"
                      title="Subir Cargue"
                      @click="dataSave()"
                      v-if="
                        cargue_programaciones.data &&
                          cargue_programaciones.data.length > 0 &&
                          cargue_programaciones.data.every(
                            (car) => car.accion_masiva > 0
                          ) &&
                          $store.getters.can(
                            'hidrocarburos.cargueProgramaciones.subirCargue'
                          )
                      "
                    >
                      <i class="fas fa-file-upload"></i> Subir Cargue
                    </button>
                  </div>
                </div>
              </div>

              <!-- Filtros -->
              <div class="row">
                <!-- Sitio Cargue -->
                <div class="form-group col-md-3">
                  <label>Sitio Cargue</label>
                  <v-select
                    placeholder="Sitio Cargue"
                    label="nombre"
                    v-model="sitio_cargue"
                    class="form-control form-control-sm shadow-sm p-0"
                    @input="selectSitioCargue()"
                    :options="listasForms.sitios"
                  >
                  </v-select>
                </div>
                <!-- Sitio Descargue -->
                <div class="form-group col-md-3">
                  <label>Sitio Descargue</label>
                  <v-select
                    placeholder="Sitio Descargue"
                    label="nombre"
                    v-model="sitio_descargue"
                    class="form-control form-control-sm shadow-sm p-0"
                    @input="selectSitioDescargue()"
                    :options="listasForms.sitiosRegalias"
                  >
                  </v-select>
                </div>
                <!-- Transportadora -->
                <div class="form-group col-md-3">
                  <label>Transportadora</label>
                  <v-select
                    placeholder="Transportadora"
                    label="razon_social"
                    v-model="transportadora"
                    class="form-control form-control-sm shadow-sm p-0"
                    @input="selectTransportadora()"
                    :options="listasForms.transportadoras"
                  >
                  </v-select>
                </div>
                <!-- Conductor -->
                <div class="form-group col-md-3">
                  <label>Conductor</label>
                  <input
                    type="text"
                    placeholder="Conductor"
                    v-model="filtros.conductor"
                    class="form-control form-control-sm shadow-sm"
                    @keyup="getIndex()"
                  />
                </div>
                <!-- Vehículo -->
                <div
                  :class="
                    det_programaciones.data &&
                    det_programaciones.data.length > 0
                      ? 'form-group col-md-2'
                      : 'form-group col-md-3'
                  "
                >
                  <label>Vehículo</label>
                  <input
                    type="text"
                    placeholder="Vehiculo"
                    v-model="filtros.vehiculo"
                    class="form-control form-control-sm shadow-sm"
                    @keyup="getIndex()"
                  />
                </div>
                <!-- Remolque -->
                <div
                  :class="
                    det_programaciones.data &&
                    det_programaciones.data.length > 0
                      ? 'form-group col-md-2'
                      : 'form-group col-md-3'
                  "
                >
                  <label>Remolque</label>
                  <input
                    type="text"
                    placeholder="Remolque"
                    v-model="filtros.remolque"
                    class="form-control form-control-sm shadow-sm"
                    @keyup="getIndex()"
                  />
                </div>
                <!-- Producto -->
                <div class="form-group col-md-3">
                  <label>Producto</label>
                  <v-select
                    placeholder="Producto"
                    v-model="producto"
                    label="nombre"
                    class="form-control form-control-sm shadow-sm p-0"
                    @input="selectProducto()"
                    :options="listasForms.productos"
                  >
                  </v-select>
                </div>
                <!-- Ruta -->
                <div class="form-group col-md-3">
                  <label>Ruta</label>
                  <v-select
                    placeholder="Ruta"
                    v-model="ruta"
                    label="nombre"
                    class="form-control form-control-sm shadow-sm p-0"
                    @input="selectRuta()"
                    :options="listasForms.rutas"
                  >
                  </v-select>
                </div>
                <div
                  class="form-group col-md-2"
                  v-if="
                    det_programaciones.data &&
                      det_programaciones.data.length > 0
                  "
                >
                  <label>Estado</label>
                  <select
                    class="form-control form-control-sm shadow-sm"
                    v-model="filtros.estado"
                    @change="getIndex()"
                  >
                    <option value="">Todos...</option>
                    <option
                      v-for="item in listasForms.det_estados"
                      :key="item.numeracion"
                      :value="item.numeracion"
                    >
                      {{ item.descripcion }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- Cargue Regalías -->
              <div
                class="card mt-2"
                v-if="cargue_programaciones.data && !bloquear"
              >
                <div>
                  <div class="card mb-0">
                    <div class="card-header bg-gradient-navy pt-2 pb-2">
                      <h3 class="card-title">
                        <i class="fas fa-clipboard text-warning"></i>
                        Regalías sin Cargar
                      </h3>
                      <div class="card-tools">
                        <button
                          type="button"
                          class="btn btn-tool"
                          data-card-widget="collapse"
                          data-toggle="tooltip"
                          title="Collapse"
                        >
                          <i class="fas fa-minus"></i>
                        </button>
                      </div>
                    </div>
                    <div class="card-body  pb-0">
                      <div class="row">
                        <!-- Convenciones -->
                        <div class="row col-md-12">
                          <div class="form-group col-md-12">
                            <h6 class="text-center mt-2">
                              <strong>Convenciones</strong>
                            </h6>
                          </div>
                          <div class="form-group col-md-4">
                            <div
                              class="progress-bar progress-bar-striped progress-bar-animated rounded shadow-sm"
                              role="progressbar"
                              :style="
                                'background-color: #5f6a6a; border: 4px solid #5f6a6a;'
                              "
                            >
                              <b> Elemento con programación activa</b>
                            </div>
                          </div>
                          <div class="form-group col-md-4">
                            <div
                              class="progress-bar progress-bar-striped progress-bar-animated rounded shadow"
                              role="progressbar"
                              :style="
                                'background-color: #050838; border: 4px solid #050838;'
                              "
                            >
                              <b> Documento no válido</b>
                            </div>
                          </div>
                          <div class="form-group col-md-4">
                            <div
                              class="progress-bar progress-bar-striped progress-bar-animated rounded shadow-sm"
                              role="progressbar"
                              :style="
                                'background-color: #1a5276; border: 4px solid #1a5276;'
                              "
                            >
                              <b> Elemento no existente en el sistema</b>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="table-responsive">
                        <table
                          class="table table-bordered table-striped table-hover table-sm shadow"
                        >
                          <thead class="bg-dark">
                            <tr>
                              <th
                                style="min-width: 10em"
                                v-if="form.estado == 1"
                              >
                                <select
                                  class="form-control form-control-sm"
                                  v-model="accion_masiva"
                                  @change="selectAccion()"
                                >
                                  <option value="">Seleccione...</option>
                                  <option
                                    v-for="accion in listasForms.acciones"
                                    :key="accion.numeracion"
                                    :value="accion.numeracion"
                                  >
                                    {{ accion.descripcion }}
                                  </option>
                                </select>
                              </th>
                              <th
                                class="text-center p-2"
                                style="min-width: 10em"
                              >
                                Documento Transporte
                              </th>
                              <th
                                class="text-center p-2"
                                style="min-width: 10em"
                              >
                                Sitio Cargue
                              </th>
                              <th
                                class="text-center p-2"
                                style="min-width: 10em"
                              >
                                Sitio Descargue
                              </th>
                              <th
                                class="text-center p-2"
                                style="min-width: 20em"
                              >
                                Transportadora
                              </th>
                              <th
                                class="text-center p-2"
                                style="min-width: 20em"
                              >
                                Conductor
                              </th>
                              <th class="text-center p-2">Vehículo</th>
                              <th class="text-center p-2">Remolque</th>
                              <th
                                class="text-center p-2"
                                style="min-width: 10em"
                              >
                                Producto
                              </th>
                              <th
                                class="text-center p-2"
                                style="min-width: 20em"
                              >
                                Ruta
                              </th>
                              <th
                                class="text-center p-2"
                                v-if="form.estado == 1"
                              >
                                Acción
                              </th>
                              <th
                                class="text-center p-2"
                                v-if="form.estado == 2"
                              >
                                Estado
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <!-- Cargue_programaciones -->
                            <tr
                              v-for="(cargue,
                              index) in cargue_programaciones.data"
                              :key="index"
                            >
                              <td class="text-center">
                                <select
                                  class="form-control form-control-sm"
                                  v-model="cargue.accion_masiva"
                                >
                                  <option value="">Seleccione...</option>
                                  <option
                                    :disabled="cargue.bloquear && index === 1"
                                    v-for="(accion,
                                    index) in listasForms.acciones"
                                    :key="accion.numeracion"
                                    :value="accion.numeracion"
                                  >
                                    {{ accion.descripcion }}
                                  </option>
                                </select>
                              </td>
                              <td class="text-center">
                                {{ cargue.doc_transporte }}
                              </td>
                              <td
                                class="text-center"
                                :style="cargue.colorSitioCar"
                              >
                                {{
                                  cargue.sitio_cargue
                                    ? cargue.sitio_cargue.nombre
                                    : ""
                                }}
                              </td>
                              <td
                                class="text-center"
                                :style="cargue.colorSitioDesc"
                              >
                                {{
                                  cargue.sitio_descargue
                                    ? cargue.sitio_descargue.nombre
                                    : ""
                                }}
                              </td>
                              <td class="text-center">
                                {{
                                  cargue.transportadora
                                    ? cargue.transportadora.razon_social
                                    : ""
                                }}
                              </td>
                              <td class="text-center" :style="cargue.colorCond">
                                {{
                                  cargue.conductor
                                    ? cargue.conductor.nombres
                                    : cargue.cond_nombres
                                }}
                                {{
                                  cargue.conductor
                                    ? cargue.conductor.apellidos
                                    : ""
                                }}
                                <span
                                  class="badge badge-info"
                                  v-if="
                                    cargue.conductor || cargue.cond_documento
                                  "
                                >
                                  {{
                                    cargue.conductor
                                      ? cargue.conductor.numero_documento
                                      : cargue.cond_documento
                                  }}
                                </span>
                              </td>
                              <td class="text-center" :style="cargue.colorVh">
                                {{
                                  cargue.vehiculo
                                    ? cargue.vehiculo.placa
                                    : cargue.placa_vh
                                }}
                              </td>
                              <td class="text-center" :style="cargue.colorRemo">
                                {{
                                  cargue.remolque
                                    ? cargue.remolque.placa
                                    : cargue.placa_rem
                                    ? cargue.placa_rem
                                    : ""
                                }}
                              </td>
                              <td class="text-center" :style="cargue.colorProd">
                                {{
                                  cargue.producto ? cargue.producto.nombre : ""
                                }}
                              </td>
                              <td class="text-center" :style="cargue.colorRuta">
                                {{ cargue.ruta ? cargue.ruta.nombre : "" }}
                              </td>
                              <td>
                                <div class="btn-group float-right">
                                  <button
                                    v-if="cargue.accion_masiva == 1"
                                    type="button"
                                    class="btn btn-sm bg-navy"
                                    data-toggle="modal"
                                    data-target="#modal_edit_cargue"
                                    @click="
                                      $refs.cargueProgramacionModal.llenarModal(
                                        cargue
                                      )
                                    "
                                  >
                                    <i class="fas fa-edit"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                            <!-- Fin cargue_programaciones -->
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="card-footer shadow-sm">
                      <!-- Paginación cargue programaciones -->
                      <div
                        class="float-left"
                        v-if="cargue_programaciones.total"
                      >
                        Mostrando del <b>{{ cargue_programaciones.from }}</b> al
                        <b>{{ cargue_programaciones.to }}</b> de un total:
                        <b>{{ cargue_programaciones.total }}</b> Registros
                      </div>
                      <div class="float-left" v-else>
                        <span class="badge badge-danger">
                          No hay registros para mostrar
                        </span>
                      </div>
                      <pagination
                        :data="cargue_programaciones"
                        @pagination-change-page="getIndex"
                        :limit="5"
                        class="float-right m-0"
                      ></pagination>
                    </div>
                    <!-- Fin paginación cargue programaciones -->
                  </div>
                </div>
              </div>

              <!-- Detalle Regalías -->
              <div
                class="card mt-2"
                v-if="
                  det_programaciones.data && det_programaciones.data.length > 0
                "
              >
                <div class="card mb-0">
                  <div class="card-header bg-gradient-navy pt-2 pb-2">
                    <h3 class="card-title">
                      <i class="fas fa-clipboard-check bg-success"></i>
                      Regalías Cargadas
                    </h3>
                    <div class="card-tools">
                      <button
                        type="button"
                        class="btn btn-tool"
                        data-card-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                      >
                        <i class="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>
                  <div class="card-body  pb-0">
                    <div class="table-responsive">
                      <table
                        class="table table-bordered table-striped table-hover table-sm shadow"
                      >
                        <thead class="bg-dark">
                          <tr>
                            <th class="text-center p-2" style="min-width: 10em">
                              Documento Transporte
                            </th>
                            <th class="text-center p-2" style="min-width: 10em">
                              Sitio Cargue
                            </th>
                            <th class="text-center p-2" style="min-width: 10em">
                              Sitio Descargue
                            </th>
                            <th class="text-center p-2" style="min-width: 20em">
                              Transportadora
                            </th>
                            <th class="text-center p-2" style="min-width: 20em">
                              Conductor
                            </th>
                            <th class="text-center p-2">Vehículo</th>
                            <th class="text-center p-2">Remolque</th>
                            <th class="text-center p-2" style="min-width: 10em">
                              Producto
                            </th>
                            <th class="text-center p-2" style="min-width: 20em">
                              Ruta
                            </th>
                            <th class="text-center p-2" v-if="form.estado == 1">
                              Acción
                            </th>
                            <th class="text-center p-2" v-if="form.estado == 2">
                              Estado
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <!-- Detalles programaciones -->
                          <tr
                            v-for="(detalle, index) in det_programaciones.data"
                            :key="index"
                          >
                            <td class="text-center">
                              {{ detalle.doc_transporte }}
                            </td>
                            <td
                              class="text-center"
                              :style="detalle.colorSitioCar"
                            >
                              {{
                                detalle.sitio_cargue
                                  ? detalle.sitio_cargue.nombre
                                  : ""
                              }}
                            </td>
                            <td
                              class="text-center"
                              :style="detalle.colorSitioDesc"
                            >
                              {{
                                detalle.sitio_descargue
                                  ? detalle.sitio_descargue.nombre
                                  : ""
                              }}
                            </td>
                            <td class="text-center">
                              {{
                                detalle.transportadora
                                  ? detalle.transportadora.razon_social
                                  : ""
                              }}
                            </td>
                            <td class="text-center" :style="detalle.colorCond">
                              {{
                                detalle.conductor
                                  ? detalle.conductor.nombres
                                  : detalle.cond_nombres
                              }}
                              {{
                                detalle.conductor
                                  ? detalle.conductor.apellidos
                                  : ""
                              }}
                              <span
                                class="badge badge-info"
                                v-if="
                                  detalle.conductor || detalle.cond_documento
                                "
                              >
                                {{
                                  detalle.conductor
                                    ? detalle.conductor.numero_documento
                                    : detalle.cond_documento
                                }}
                              </span>
                            </td>
                            <td class="text-center" :style="detalle.colorVh">
                              {{
                                detalle.vehiculo
                                  ? detalle.vehiculo.placa
                                  : detalle.placa_vh
                              }}
                            </td>
                            <td class="text-center" :style="detalle.colorRemo">
                              {{
                                detalle.remolque
                                  ? detalle.remolque.placa
                                  : detalle.placa_rem
                                  ? detalle.placa_rem
                                  : ""
                              }}
                            </td>
                            <td class="text-center" :style="detalle.colorProd">
                              {{
                                detalle.producto ? detalle.producto.nombre : ""
                              }}
                            </td>
                            <td class="text-center" :style="detalle.colorRuta">
                              {{ detalle.ruta ? detalle.ruta.nombre : "" }}
                            </td>
                            <td class="text-center">
                              <div
                                v-if="
                                  detalle.estado !== null &&
                                    detalle.estado != ''
                                "
                              >
                                <div v-if="detalle.estado == 1">
                                  <span class="badge badge-warning">
                                    {{ detalle.nEstado }}
                                  </span>
                                </div>
                                <div v-if="detalle.estado == 2">
                                  <span class="badge badge-primary">
                                    {{ detalle.nEstado }}
                                  </span>
                                </div>
                                <div v-if="detalle.estado == 3">
                                  <span class="badge bg-maroon">
                                    {{ detalle.nEstado }}
                                  </span>
                                </div>
                                <div v-if="detalle.estado == 4">
                                  <span class="badge badge-success">
                                    {{ detalle.nEstado }}
                                  </span>
                                </div>
                                <div v-if="detalle.estado == 5">
                                  <span class="badge badge-danger">
                                    {{ detalle.nEstado }}
                                  </span>
                                </div>
                                <div v-if="detalle.estado == 6">
                                  <span class="badge badge-success">
                                    {{ detalle.nEstado }}
                                  </span>
                                </div>
                                <div v-if="detalle.estado == 7">
                                  <span class="badge badge-dark">
                                    {{ detalle.nEstado }}
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <!-- Fin detalles programaciones -->
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="card-footer shadow-sm">
                    <!-- Paginación detalles programaciones -->
                    <div class="float-left" v-if="det_programaciones.total">
                      Mostrando del <b>{{ det_programaciones.from }}</b> al
                      <b>{{ det_programaciones.to }}</b> de un total:
                      <b>{{ det_programaciones.total }}</b> Registros
                    </div>
                    <div class="float-left" v-else>
                      <span class="badge badge-danger">
                        No hay registros para mostrar
                      </span>
                    </div>
                    <pagination
                      :data="det_programaciones"
                      @pagination-change-page="getIndex"
                      :limit="5"
                      class="float-right m-0"
                    ></pagination>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </section>
      </div>
      <CargueProgramacionModal ref="cargueProgramacionModal" />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapState } from "vuex";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import CargueProgramacionModal from "./CargueProgramacionModal";

export default {
  name: "ProgramacionRegaliasForm",
  components: {
    Loading,
    pagination,
    vSelect,
    CargueProgramacionModal,
  },
  data() {
    return {
      cargando: false,
      hoy: moment().format("YYYY-MM-DD"),
      accion_masiva: null,
      accion: null,
      fecha_vigencia: null,
      hora_vigencia: null,
      sitio_cargue: {},
      sitio_descargue: {},
      transportadora: {},
      producto: {},
      ruta: {},
      cargue_programaciones: {},
      det_programaciones: {},
      plantilla_cargue: null,
      metodo: null,
      vencida: false,
      bloquear: false,
      form: {
        id: null,
        nombre: null,
        fecha_vigencia: null,
        nominacion_id: null,
        observaciones: null,
        estado: null,
      },
      filtros: {
        estado: null,
        programacion_id: null,
        sitio_cargue_id: null,
        sitio_descargue_id: null,
        transportadora_id: null,
        ruta_id: null,
        producto_id: null,
        conductor: null,
        vehiculo: null,
        remolque: null,
      },
      listasForms: {
        acciones: [],
        sitios: [],
        productos: [],
        transportadoras: [],
        rutas: [],
        estados: [],
        det_estados: [],
        nominaciones: [],
        sitiosRegalias: [],
      },
      nominacion: [],
    };
  },
  validations: {
    form: {
      fecha_vigencia: {
        required,
      },
      nombre: {
        required,
      },
      nominacion_id: {
        required,
      },
    },
  },

  methods: {
    async getIndex(page = 1) {
      let me = this;
      await me.GetNominacion();
      me.cargando = true;
      me.accion = me.$route.params.accion;
      if (me.accion == "Crear") {
        me.metodo = "post";
        me.form.estado = 1;
        me.cargando = false;
      }
      if (me.accion == "Editar") {
        me.form = me.$route.params.data_edit;
        me.filtros.programacion_id = me.form.id;
        me.nominacion = me.listasForms.nominaciones.filter((item) => {
          return item.id === me.form.nominacion_id;
        });
        me.metodo = "put";
        //Se valida la fecha de vigencia es mayor a la fecha actual
        if (me.form.fecha_vigencia) {
          if (me.form.fecha_vigencia < me.hoy) {
            me.vencida = true;
          }
        }
        //Busca los detalles de programaciones cargadas
        await axios
          .get(
            "/api/hidrocarburos/cargueProgramaciones/detalles?page=" + page,
            {
              params: me.filtros,
            }
          )
          .then(async (response) => {
            me.det_programaciones = await response.data;
          });

        //Busca los detalles de programaciones sin cargar
        await axios
          .get("/api/hidrocarburos/cargueProgramaciones?page=" + page, {
            params: me.filtros,
          })
          .then(async (response) => {
            await me.getDataColores(response.data);
            me.cargando = false;
          });

        if (me.form.estado == 2) {
          me.bloquear = true;
        }
      }
    },

    elegirDirectorio() {
      this.plantilla_cargue = this.$refs.file.files[0];
    },

    limpiarPlantilla() {
      const fileInput = document.getElementById("file");
      if (fileInput) {
        fileInput.value = null;
      }
      this.plantilla_cargue = null;
    },

    descargarPlantilla() {
      this.cargando = true;
      axios({
        method: "GET",
        url: "/api/hidrocarburos/cargueProgramaciones/plantilla",
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
          this.$swal({
            icon: "success",
            title: data.message,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    //Carga y/o valida los datos de la plantilla (Excel de Cargue Programaciones)
    async importarCargue() {
      this.cargando = true;
      let formData = new FormData();
      formData.append("programacion_id", this.form.id);
      formData.append("file", this.plantilla_cargue);
      formData.append("nombre", this.form.nombre);
      formData.append("fecha_vigencia", this.form.fecha_vigencia);
      formData.append("observaciones", this.form.observaciones);
      await axios
        .post(
          "/api/hidrocarburos/cargueProgramaciones/importCargue",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(async (response) => {
          let result_content =
            response.data.error.length > 0
              ? `<div>
                <p class="text-danger">
                  <strong>La plantilla no se cargo.</strong>
                </p>
              </div>`
              : `<div>
                <p class="text-success">
                  <strong>La plantilla se cargo exitosamente.</strong>
                </p>
              </div>`;
          if (response.data.error.length > 0) {
            result_content += `<h4>Errores encontrados.</h4>`;
            response.data.error.forEach(
              (err) => (result_content += `<div><small>${err}</small></div>`)
            );
          }
          this.cargando = false;
          await this.$swal({
            title: "Resultado de la carga.",
            html: result_content,
            icon: response.data.error.length > 0 ? "error" : "success",
            showCancelButton: false,
            confirmButtonText: "Ok",
            //width: "800px",
            backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
            allowOutsideClick: false,
          }).then(async (result) => {
            if (result.value) {
              await this.getIndex();
            }
          });
          this.limpiarPlantilla();
        })
        .catch((e) => {
          console.log(e);
          this.$swal({
            icon: "error",
            title: "Ocurrio un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    async getDataColores(cargues) {
      for (let i = 0; i < cargues.data.length; i++) {
        let proExis = null;
        let documentos = null;
        let colorVh = "";
        let colorRemo = "";
        let colorCond = "";
        let colorRuta = "";
        let colorSitioCar = "";
        let colorSitioDesc = "";
        let colorTrans = "";
        let colorProd = "";
        let accion_masiva = "";
        let bloquear = false;

        /****************Vehículo************/
        //Sino existe en el sistema
        if (!cargues.data[i].vehiculo_id) {
          colorVh = "background: #1a5276; color:  #fbfcfc;";
        }

        if (cargues.data[i].vehiculo_id) {
          //Si tiene una programación activa
          proExis = await this.programacionExist(
            "V",
            cargues.data[i].vehiculo_id
          );

          if (proExis.length > 0) {
            colorVh = "background: #5f6a6a; color:  #fbfcfc;";
            accion_masiva = 1;
            bloquear = true;
          }

          //Si la entidad esta activa y tiene documentación
          documentos = await this.documentEntidad(
            "Vehiculo",
            cargues.data[i].vehiculo_id,
            cargues.data[0].fecha_vigencia
          );

          if (!documentos.success) {
            colorVh = "background: #050838; color:  #fbfcfc;";
            accion_masiva = 1;
            bloquear = true;
          }
        }

        /****************Remolque************/
        //Sino existe en el sistema
        if (!cargues.data[i].remolque_id) {
          colorRemo = "background: #1a5276; color:  #fbfcfc;";
        }

        if (cargues.data[i].remolque_id) {
          //Si tiene una programación activa
          proExis = await this.programacionExist(
            "R",
            cargues.data[i].remolque_id
          );
          if (proExis.length > 0) {
            colorRemo = "background: #5f6a6a; color:  #fbfcfc;";
            accion_masiva = 1;
            bloquear = true;
          }

          //Si la entidad esta activa y tiene documentación
          documentos = await this.documentEntidad(
            "Remolque",
            cargues.data[i].remolque_id,
            cargues.data[0].fecha_vigencia
          );

          if (!documentos.success) {
            colorRemo = "background: #050838; color:  #fbfcfc;";
            accion_masiva = 1;
            bloquear = true;
          }
        }

        /****************Conductor************/
        //Si no existe en el sistema
        if (!cargues.data[i].conductor_id) {
          colorCond = "background: #1a5276; color:  #fbfcfc;";
        }

        if (cargues.data[i].conductor_id) {
          //Si tiene una programación activa
          proExis = await this.programacionExist(
            "C",
            cargues.data[i].conductor_id
          );

          if (proExis.length > 0) {
            colorCond = "background: #5f6a6a; color:  #fbfcfc;";
            accion_masiva = 1;
            bloquear = true;
          }

          //Si la entidad esta activa y tiene documentación
          documentos = await this.documentEntidad(
            "Conductor",
            cargues.data[i].conductor_id,
            cargues.data[0].fecha_vigencia
          );

          if (!documentos.success) {
            colorCond = "background: #050838; color:  #fbfcfc;";
            accion_masiva = 1;
            bloquear = true;
          }
        }

        /****************Ruta************/
        //Sino existe en el sistema
        if (!cargues.data[i].ruta_id) {
          colorRuta = "background: #1a5276; color:  #fbfcfc;";
        }

        /****************Sitio Cargue************/
        //Sino existe en el sistema
        if (!cargues.data[i].sitio_cargue_id) {
          colorSitioCar = "background: #1a5276; color:  #fbfcfc;";
        }

        /****************Sitio Descargue************/
        //Sino existe en el sistema
        if (!cargues.data[i].sitio_descargue_id) {
          colorSitioDesc = "background: #1a5276; color:  #fbfcfc;";
        }

        /****************Transportadora************/
        //Sino existe en el sistema
        if (!cargues.data[i].transportadora_id) {
          colorTrans = "background: #1a5276; color:  #fbfcfc;";
        }

        /****************Producto************/
        //Sino existe en el sistema
        if (!cargues.data[i].producto_id) {
          colorProd = "background: #1a5276; color:  #fbfcfc;";
        }
        cargues.data[i].accion_masiva = accion_masiva;
        cargues.data[i].bloquear = bloquear;
        cargues.data[i].colorVh = colorVh;
        cargues.data[i].colorRemo = colorRemo;
        cargues.data[i].colorCond = colorCond;
        cargues.data[i].colorRuta = colorRuta;
        cargues.data[i].colorSitioCar = colorSitioCar;
        cargues.data[i].colorSitioDesc = colorSitioDesc;
        cargues.data[i].colorTrans = colorTrans;
        cargues.data[i].colorProd = colorProd;
      }

      this.cargue_programaciones = cargues;
    },

    async programacionExist(entidad, entidad_id) {
      let proExist = null;
      let vehiculo_id = entidad === "V" ? entidad_id : null;
      let remolque_id = entidad === "R" ? entidad_id : null;
      let conductor_id = entidad === "C" ? entidad_id : null;

      let params = {
        vehiculo_id,
        remolque_id,
        conductor_id,
      };

      await axios
        .get("/api/hidrocarburos/cargueProgramaciones/programacionExist", {
          params,
        })
        .then((response) => {
          proExist = response.data;
        });
      return proExist;
    },

    async documentEntidad(entidad, entidad_id, fecha) {
      let documentos = null;
      let params = {
        entidad,
        entidad_id,
        fecha,
      };

      await axios
        .get("/api/admin/documentos/validaDocumentos", {
          params,
        })
        .then((response) => {
          documentos = response.data;
        });
      return documentos;
    },

    // Funciones de carga de los select del módulo
    async getSitio() {
      let me = this;
      var url = "api/admin/sitios/lista?sitio";
      await axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.sitios = respuesta;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getSitioRegalias() {
      let me = this;
      var url = "api/admin/destinosEcopetrol/lista";
      await axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.sitiosRegalias = respuesta;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getTransportadora() {
      let me = this;
      var url = "api/admin/empresas/lista?transportadora";
      await axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.transportadoras = respuesta;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getProducto() {
      let me = this;
      //var url = "api/admin/productos/lista?producto";
      var url = "api/hidrocarburos/Productos/lista";
      await axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.productos = respuesta;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getRuta() {
      let me = this;
      //var url = "api/admin/rutas/lista?ruta";
      var url = "api/admin/rutasEcopetrol/lista";
      await axios
        .get(url)
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.rutas = respuesta;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async acciones() {
      this.listasForms.acciones = [
        {
          numeracion: 1,
          descripcion: "Editar/Omitir",
        },
        {
          numeracion: 2,
          descripcion: "Cargar",
        },
      ];
    },

    selectProducto() {
      this.filtros.producto_id = null;
      if (this.producto) {
        this.filtros.producto_id = this.producto.id;
      }
      this.getIndex();
    },

    selectRuta() {
      this.filtros.ruta_id = null;
      if (this.ruta) {
        this.filtros.ruta_id = this.ruta.id;
      }
      this.getIndex();
    },

    selectSitioCargue() {
      this.filtros.sitio_cargue_id = null;
      if (this.sitio_cargue) {
        this.filtros.sitio_cargue_id = this.sitio_cargue.id;
      }
      this.getIndex();
    },

    selectSitioDescargue() {
      this.filtros.sitio_descargue_id = null;
      if (this.sitio_descargue) {
        this.filtros.sitio_descargue_id = this.sitio_descargue.id;
      }
      this.getIndex();
    },

    selectTransportadora() {
      this.filtros.transportadora_id = null;
      if (this.transportadora) {
        this.filtros.transportadora_id = this.transportadora.id;
      }
      this.getIndex();
    },

    selectAccion() {
      this.cargue_programaciones.data.forEach((element) => {
        if (element.bloquear == false) {
          element.accion_masiva = this.accion_masiva;
        }
      });
    },

    //Guarda detalles de programaciones () y edita el estado de cargue programaciones
    async subirCargue(carguePro) {
      this.cargando = true;
      let formCargues = carguePro;
      await axios({
        method: "PUT",
        url: "/api/hidrocarburos/cargueProgramaciones/subirCargue",
        data: { formCargues: formCargues },
      })
        .then(async (response) => {
          await this.getIndex();
          this.accion_masiva = null;
          await this.$swal({
            icon: "success",
            title: "Se subio el cargue exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    async dataSave() {
      let uniqueDocs = new Set();
      let carguePro = [];

      // Se valida que no se repita doc_transporte
      this.cargue_programaciones.data.forEach((element) => {
        if (!uniqueDocs.has(element.doc_transporte)) {
          uniqueDocs.add(element.doc_transporte);
          carguePro.push({
            programacion_id: this.form.id,
            cargue_id: element.id,
            vehiculo_id: element.vehiculo_id,
            placa_vh: element.placa_vh,
            remolque_id: element.remolque_id,
            placa_rem: element.placa_rem,
            conductor_id: element.conductor_id,
            cond_nombres: element.cond_nombres,
            cond_documento: element.cond_documento,
            producto_id: element.producto_id,
            ruta_id: element.ruta_id,
            doc_transporte: element.doc_transporte,
            sitio_cargue_id: element.sitio_cargue_id,
            sitio_descargue_id: element.sitio_descargue_id,
            transportadora_id: element.transportadora_id,
            vigencia_guia: element.vigencia_guia,
            observaciones: element.observaciones,
            accion_masiva: element.accion_masiva,
          });
        }
      });
      await this.subirCargue(carguePro);
    },

    //Guarda datos del maestro
    save() {
      this.cargando = true;

      axios({
        method: this.metodo,
        url: "api/hidrocarburos/cargueProgramaciones",
        params: this.form,
      })
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              icon: "error",
              title: response.data.error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            this.accion = "Editar";
            this.metodo = "put";
            this.form.id = response.data.id;
            this.bloquear = response.data.estado == 2 ? true : false;
            this.vencida =
              response.data.fecha_vigencia < this.hoy ? true : false;
          }

          this.cargando = false;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    validarEstado() {
      if (
        this.form.estado == 2 &&
        this.cargue_programaciones.data &&
        this.cargue_programaciones.data.length > 0
      ) {
        this.$swal({
          title: "Error al actualizar.",
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "Ok",
          text:
            "No es posible cambiar el estado, si existen regalías sin cargar.",
          //width: "800px",
          backdrop: `rgba(0,0,123,0.4) left top no-repeat`,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.value) {
            //this.getIndex();
            this.form.estado = 1;
          }
        });
      }
    },

    back() {
      return this.$router.push({
        name: "/Hidrocarburos/ProgramacionesRegalias",
      });
    },

    async GetNominacion() {
      let me = this;
      await axios
        .get("api/hidrocarburos/nominacionesRegalias/lista?estado=2")
        .then(function(response) {
          me.listasForms.nominaciones = response.data;
        })
        .catch(function(error) {
          me.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    validarNominación() {
      this.form.fecha_vigencia = null;
      this.form.nominacion_id = this.nominacion ? this.nominacion.id : null;
    },

    ...mapActions("modOperacion", ["actGetListas", "actGetEmpresas"]),
  },

  computed: {
    ...mapState("modOperacion", ["listas", "empresas"]),
  },

  async beforeMount() {
    await this.acciones();
    await this.getProducto();
    await this.getRuta();
    //await this.GetNominacion();
    await this.getSitio();
    await this.getSitioRegalias();
  },

  async mounted() {
    //lista de estados para el maestro
    await this.actGetListas(28).then(async () => {
      this.listasForms.estados = await this.listas;
    });
    //lista de estados para los detalles de programación
    await this.actGetListas(65).then(async () => {
      this.listasForms.det_estados = await this.listas;
    });
    await this.actGetEmpresas().then(async () => {
      this.listasForms.transportadoras = await this.empresas;
    });
    await this.getIndex();
  },
};
</script>

<style>
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.well {
  height: 100%;
  min-height: 20px;
  padding: 14px;
  margin: auto;
  margin-bottom: 10px;
  width: 100%;
  border: 3px solid #04073c;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well-legend {
  display: block;
  font-size: 20px;
  width: auto;
  min-width: 300px;
  padding: 2px 7px 2px 5px;
  line-height: unset;
  border-radius: 4px;
  border: 2px solid #04073c;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
</style>
